import {pathologyUrl} from "utils/pathologyUrlHelper";

// TODO LUCAS MIGRATE ALL USE CASES TO AIRTABLE
export const specialties = [
  {
    slug: 'sexualite',
    familySlug: 'sexual',
    categorySlug : "sante-sexuelle",
    name: 'Santé sexuelle',
    title: "Les pathologies sexuelles",
    squareName: 'Santé sexuelle',
    description: "Résolvez vos problèmes intimes avec l'aide de nos médecins sexologues.",
    showOnlyMain: true,
    pathologies: [
      {
        name: "Problème d'érection",
        slug: 'problemes-erection',
        image: 'new-images/probleme-erection.jpg',
      },
      {
        name: 'Éjaculation précoce',
        slug: 'ejaculation-precoce',
        image: 'new-images/ejaculation-precoce.jpg',
      },
      {
        name: 'Perte de libido',
        slug: 'baisse-de-libido',
        image: 'new-images/baisse-libido.jpg',
      },
      {
        name: 'Andropause',
        slug: 'andropause',
        image: 'new-images/andropause.jpg',
      },
      {
        name: 'Syphilis',
        slug: 'syphilis',
        image: 'new-images/syphilis.jpg',
      },
      {
        name: 'Chlamydia',
        slug: 'chlamydia',
        image: 'new-images/chlamydia.jpg',
      },
      {
        name: 'Augmenter testostérone',
        slug: 'augmenter-testosterone',
        image: 'new-images/augmenter-testosterone.jpg',
      },
      {
        name: 'Impuissance',
        slug: 'impuissance',
        image: 'new-images/impuissance.jpg',
      },
      {
        name: 'Condylome',
        slug: 'condylome',
        image: 'new-images/condylome.jpg',
      },
      {
        name: 'Testicule gonflé',
        slug: 'testicule-gonfle',
        image: 'new-images/testicule-gonfle.jpg',
      },
      {
        name: 'Agrandir pénis',
        slug: 'agrandir-penis',
        image: 'new-images/agrandir-penis.jpg',
      },
      {
        name: 'IST',
        slug: 'ist',
        image: 'new-images/ist.jpg',
      },
      {
        name: 'Balanite',
        slug: 'balanite',
        image: 'new-images/balanite.jpg',
      },
      {
        name: 'Herpès génital',
        slug: 'herpes-genital',
        image: 'new-images/herpes-genital.jpg',
      },
      {
        name: 'Micropénis',
        slug: 'micropenis',
        image: 'new-images/micro-penis.jpg',
      },
      {
        name: 'Absence de désir',
        slug: 'absence-de-desir',
        image: 'new-images/absence-de-desir.jpg',
      },
      {
        name: 'Autres troubles sexuels',
        slug: 'troubles-sexuels',
        image: 'new-images/troubles-sexuels.jpg',
      },
    ],
    icon: 'icon-sex.svg',
    color: '#3D7791',
    image: 'sex-illustration',
    alt: 'sante-sexuelle-icon',
    imageTitle: 'sante sexuelle icon',
    imgTitle: 'sante sexuelle illustration', // alt of the background image call by filename={`new-images/${spe.image}.jpg`} in MedicalSpecialities
    imgAlt: 'sante-sexuelle-illustration', // same but for title
  },
  {
    slug: 'sommeil',
    familySlug: 'sleep',
    categorySlug : "sommeil",
    name: 'Troubles du sommeil',
    title: "Les pathologies du sommeil",
    squareName: 'Sommeil',
    description: 'Réalisez un bilan de sommeil avec nos médecins experts et obtenez un plan de traitement complet.',
    showOnlyMain: false,
    directLinkOnHome: pathologyUrl("sommeil", "troubles-du-sommeil"),
    pathologies: [
      {
        name: 'Insomnie',
        slug: 'troubles-du-sommeil',
        consultationSlug : 'sommeil',
        image: 'new-images/sommeil/pathologies/insomnie.jpg',
      },
      {
        name: 'Mauvais sommeil',
        slug: 'manque-de-sommeil',
        consultationSlug : 'sommeil',
        image: 'new-images/sommeil/pathologies/trouble-du-sommeil.jpg',
      },
      {
        name: 'Somnolence',
        slug: 'somnolence',
        consultationSlug : 'sommeil',
        image: 'new-images/sommeil/pathologies/somnolence.jpg',
      },

    ],
    icon: 'sleep-icon.svg',
    color: '#AF6345',
    image: 'sleeping-woman',
    alt: 'sommeil-icon',
    imageTitle: 'sommeil icon',
    imgTitle: 'sommeil illustration', // alt of the background image call by filename={`new-images/${spe.image}.jpg`} in MedicalSpecialities
    imgAlt: 'sommeil-illustration', // same but for title
  }, {
    slug: 'hair',
    familySlug: 'hair',
    name: 'Perte de cheveux',
    title: "Les pathologies du cheveu",
    categorySlug : "cheveux",
    squareName: 'Cheveux',
    description: "Faites un diagnostic de votre chute de cheveux avec un médecin et obtenez des traitements à l'efficacité prouvée.",
    showOnlyMain: false,
    directLinkOnHome: pathologyUrl("cheveux", "perte-de-cheveux"),
    pathologies: [
      {
        name: 'Alopécie',
        slug: 'alopecie',
        consultationSlug : 'cheveux',
        image: 'new-images/hub-cheveux/alopecie.jpg',
      },
      {
        name: 'Chute de cheveux',
        slug: 'perte-de-cheveux',
        consultationSlug : 'cheveux',
        image: 'new-images/hub-cheveux/perte-de-cheveux.jpg',
      },
      {
        name: 'Calvitie',
        slug: 'calvitie',
        consultationSlug : 'cheveux',
        image: 'new-images/hub-cheveux/calvitie.jpg',
      },

    ],
    icon: 'hair-icon.svg',
    color: '#E1C341',
    image: 'cheveux-home',
    alt: 'cheveux-icon',
    imageTitle: 'cheveux icon',
    imgTitle: 'cheveux illustration', // alt of the background image call by filename={`new-images/${spe.image}.jpg`} in MedicalSpecialities
    imgAlt: 'cheveux-illustration', // same but for title
  },
];
// TODO NEXTGEN
const nextGenFamilies = [
  {
    slug: 'digestion',
    familySlug: 'digestion',
    categorySlug : "digestion",
    name: 'Troubles digestifs',
    squareName: 'Digestion',
    description: 'Retrouvez une digestion facile grâce aux protocoles de nos médecins experts.',
    showOnlyMain: false,
    directLinkOnHome: '/digestion/',
    pathologies: [
      {
        name: 'Troubles du transit',
        slug: 'digestion',
      },
      {
        name: 'Ballonnements, gaz',
        slug: 'digestion',
      },
      {
        name: 'Brûlures, reflux',
        slug: 'digestion',
      },
      {
        name: 'Douleurs, colites',
        slug: 'digestion',
      },
    ],
    icon: 'digestion-icon.svg',
    color: '#4F785F',
    image: 'digestion-home',
    alt: 'digestion-icon',
    imageTitle: 'digestion icon',
    imgTitle: 'digestion illustration', // alt of the background image call by filename={`new-images/${spe.image}.jpg`} in MedicalSpecialities
    imgAlt: 'digestion-illustration', // same but for title

  },
];