import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Layout } from 'components/common';
import { specialties } from 'components/common/data/specialties';
import Image from 'components/common/utils/Image';
import { trackCategoryChosen } from 'utils/segment'

import ReinsuranceColumn from '../Template/ReinsuranceColumn';
import TopContainerConsulter from '../Template/TopContainerConsulter';

import './styles.sass';

const StartLink = ({ pathology: { slug, title } }) => (
  <Link
    to={`/${slug}/`}
    className="btn btn-success font-weight-bold text-white start-call-to-action start-general-cta"
  >
    {title}
  </Link>
);

const IndexPage = () => (
  <>
    <Helmet defer={false}>
      <html className="html-class-small-font-page" />
    </Helmet>
    <Layout showBars={false} showM6Banner={false}>
      <div className="text-center text-md-left start-page w-100">
        <TopContainerConsulter />
        <div className="main-container-wrapper">
          <Container className="pb-3 pb-sm-5 main-container px-4">
            <Row className="main-start-panel">
              <Col xs={12} xl={{ span: 8 }} className="left-col">
                <div className="d-flex flex-column left-div">
                  <h1 className="section-title main-title text-center ">
                    Nos spécialistes sont à votre écoute
                  </h1>
                  <p className="start-text text-center d-flex start-text-general">
                    Sélectionnez le trouble auquel vous faites face pour que
                    l&apos;on puisse adapter le questionnaire médical et vous
                    mettre en relation avec un médecin spécialisé.
                  </p>
                  <div className="landing-home-main-div">
                    <div className="landing-specialties-and-image-wrapper">
                      <div className="landing-specialties">
                        <div className="landing-specialties-card-wrapper-consulter">
                          {specialties.map((spe, i) => (
                            <Link
                              className="landing-specialty-card"
                              style={{ border: `3px solid ${spe.color}` }}
                              to={
                                spe.showOnlyMain === true
                                  ? `/consulter-${spe.slug}/`
                                  : `/consulter-${spe.pathologies[0].consultationSlug}/`
                              }
                              onClick={() => trackCategoryChosen(spe.familySlug)}
                              rel="nofollow"
                            >
                              <div className="landing-specialty-card-image">
                                <Image
                                  filename={`new-images/icons/${spe.icon}`}
                                  alt={spe.alt}
                                  title={spe.imageTitle}
                                />
                              </div>
                              <div className="landing-specialty-card-name">
                                <span>{spe.squareName}</span>
                                <Image
                                  filename="new-images/right-tag.svg"
                                  alt="fleche-droite-picto"
                                  title="fleche droite picto"
                                />
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <div className="start-general-separator-div">
                <div className="start-general-separator d-xl-none flex-column " />
              </div>
              <Col className="col-separator" xs={0} xl={1}>
                <div className="start-general-separator-xl " />
              </Col>
              <Col xs={12} xl={{ span: 3 }} className="right-col">
                <ReinsuranceColumn price="35€"/>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  </>
);

export default IndexPage;
